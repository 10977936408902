import { render, staticRenderFns } from "./FieldAccidentPdf.vue?vue&type=template&id=49241a3f&"
import script from "./FieldAccidentPdf.vue?vue&type=script&lang=js&"
export * from "./FieldAccidentPdf.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Mobaxe\\Betterway.vms\\Betterway.VMS.web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49241a3f')) {
      api.createRecord('49241a3f', component.options)
    } else {
      api.reload('49241a3f', component.options)
    }
    module.hot.accept("./FieldAccidentPdf.vue?vue&type=template&id=49241a3f&", function () {
      api.rerender('49241a3f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Fields/FieldAccidentPdf.vue"
export default component.exports