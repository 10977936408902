<template>
    <div class="Field__Component">
        <div><slot>
            <a target="_blank" :href="`https://app.betterway.co.il/i/${value}`">
                <b-button type="is-primary" size="is-small">פתח</b-button>
            </a>
        </slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldCustomerLink',
    props: ['value', 'item'],
};
</script>

<style scoped>
    .Field__Component a {
        text-align: center;
        display: block;
    }
</style>
