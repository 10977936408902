<template>
    <div class="Field__Component">
        <div><slot> {{value ? 'יש':'אין'}}</slot></div>
    </div>
</template>

<script>
export default {
    name: 'FieldImage',
    props: ['value', 'item'],
};
</script>

<style scoped>

</style>
