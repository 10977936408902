const fields = {
    reports: {
        Address: {
            Display: 'כתובת',
            Id: 'Address',
            Type: 'FieldStandard',
            Tooltip: 'המיקום בו נרשם הדוח ע"פ הפקח',
            Sortable: true,
            Order: 18,
        },
        Alerts: {
            Display: 'התראות',
            Id: 'Alerts',
            Type: 'FieldAlerts',
            Tooltip: 'התראות על דוח מציינות שלדוח זה קיימים פרמטרים העשויים להשפיע על תהליך הטיפול של החברה',
        },
        ApprovalDate: {
            Display: 'ת. אישור תשלום',
            Id: 'ApprovalDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו הדוח אושר לתשלום מטעם החברה',
            Sortable: true,
            Order: 11,
        },
        ClientComment: {
            Order: 21,
            Display: 'הערת לקוח',
            Id: 'ClientComment',
            Type: 'FieldStandard',
            Tooltip: 'הערת לקוח הינה אישית למשתמש',
            Sortable: true,
        },
        CreateDate: {
            Display: 'ת. יצירה',
            Id: 'CreateDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו הדוח התווסף למערכת',
            Sortable: true,
            Order: 9,
        },
        CurrentAmount: {
            Order: 16,
            Display: 'יתרה לתשלום',
            Id: 'CurrentAmount',
            Type: 'FieldCurrency',
            Tooltip: 'הסכום הנותר לתשלום',
            Sortable: true,
        },
        TransferCount: {
          Order: 17,
          Display: 'מספר הסבות',
          Id: 'TransferCount',
          Type: 'FieldStandard',
          ToolTip: 'מספר ההסבות'
        },
        DateTaken: {
            Order: 10,
            Display: 'ת. עבירה',
            Id: 'DateTaken',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך והשעה בו הדוח נתקבל הדוח מאת הפקח',
            Sortable: true,
        },
        Driver: {
            Order: 7,
            Display: 'נהג',
            Id: 'Driver',
            Type: 'FieldDriver',
            Tooltip: 'הנהג ברכב בזמן ביצוע העבירה',
            Sortable: true,
        },
        DriverAmount: {
            Display: 'תשלום נהג',
            Id: 'DriverAmount',
            Type: 'FieldNumeric',
            Tooltip: 'הסכום שנקבע לתשלום מאת הנהג',
            Sortable: true,
            Order: 7.1,
        },
        IsPaidByDriver: {
            Display: 'שולם ע"י נהג',
            Id: 'IsPaidByDriver',
            Type: 'FieldBoolean',
            Tooltip: 'האם הדוח שולם ע"י הנהג',
            Order: 7.2,
        },
        DriverTransferCompleteDate: {
            Display: 'ת. השלמת הסבת נהג',
            Id: 'DriverTransferCompleteDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו נתקבל מהעיריה אישור להסבת הדוח',
            Sortable: true,
        },
        DriverTransferError: {
            Display: 'חוסרים',
            Id: 'DriverTransferError',
            Type: 'FieldStandard',
            Tooltip: 'הסיבה לכך שההסבה נכשלה',
        },
        DriverTransferPdfUrl: {
            Display: 'קובץ הסבת נהג',
            Id: 'DriverTransferPdfUrl',
            mapItem: (row) => row,
            Type: 'FieldFileDriver',
            Tooltip: 'קובץ PDF עם טופס ההסבה של נהג',
        },
        DriverTransferSignedDate: {
            Display: 'ת. חתימת ע"וד להסבת נהג',
            Id: 'DriverTransferSignedDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך והשעה בו עו"ד חתם על הסבת הדוח לנהג',
            Sortable: true,
            Order: 25,
        },
        DriverTransferStatus: {
            Display: 'סטטוס הסבת נהג',
            Id: 'DriverTransferStatus',
            Type: 'FieldStandard',
            Tooltip: 'סטטוס ההסבה של נהג ע"פ המערכת',
            Order: 23.1,
        },
        DriverTransferredDate: {
            Display: 'ת. הפקת הסבת נהג',
            Id: 'DriverTransferredDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך והשעה בו הוגשה ההסבה לעיריה',
            Sortable: true,
            Order: 23,
        },
        HasScreenshot: {
            Display: 'תצלום מסך',
            Id: 'HasScreenshot',
            Type: 'FieldImage',
            Tooltip: 'תצלום מסך של הגשת ההסבה דרך אתר העיריה',
        },
        Id: {
            Order: 0,
            Display: 'מזהה',
            Id: 'Id',
            Type: 'FieldNumeric',
            Tooltip: 'מזהה שורה',
            AdminOnly: true,
            Sortable: true,
        },
        IsPaid: {
            Display: 'שולם',
            Id: 'IsPaid',
            Type: 'FieldBoolean',
            Tooltip: 'סטטוס תשלום הדוח',
        },
        LastPayDate: {
            Order: 13,
            Display: 'ת. אחרון לתשלום',
            Id: 'LastPayDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך האחרון הנמסר ע"פ העיריה לתשלום הדוח',
            Sortable: true,
        },
        ManagerComment: {
            Display: 'הערת מנהל',
            Id: 'ManagerComment',
            Type: 'FieldStandard',
            Tooltip: 'הערת מנהל ניתנת לצפייה ע"י מנהל בלבד',
            AdminOnly: true,
            Sortable: true,
            Order: 22,
        },
        MuniIdNumber: {
            Display: 'מזהה עיריה',
            Id: 'MuniIdNumber',
            Type: 'FieldNumeric',
            Tooltip: 'הח.פ/ת.ז של מבצע העבירה כפי שמופיע במאגר נתוני העיריה',
            Sortable: true,
            Order: 3,
        },
        MuniReferenceNumber: {
            Display: 'אסמכתה ירושלים',
            Id: 'MuniReferenceNumber',
            Type: 'FieldNumeric',
            Tooltip: 'הח.פ/ת.ז של מבצע העבירה כפי שמופיע בעירית ירושלים',
            Order: 4,
        },
        Municipality: {
            Order: 17,
            Display: 'עיריה',
            Id: 'Municipality',
            Type: 'FieldStandard',
            Tooltip: 'הרשות שמטעמה נתקבל הדוח',
            Sortable: true,
        },
        OriginalAmount: {
            Order: 14,
            Display: 'סכום מקור',
            Id: 'OriginalAmount',
            Type: 'FieldCurrency',
            Tooltip: 'הסכום המקורי שנתקבל בזמן מתן הדוח',
            Sortable: true,
        },
        OwnerName: {
            Display: 'חברה',
            Id: 'OwnerName',
            Type: 'FieldStandard',
            Tooltip: 'הבעלים המקורי של הרכב כפי שמצויין במשרד הרישוי',
            Order: 2.2,
        },
        PartialPaidAmount: {
            Display: 'תשלום חלקי',
            Id: 'PartialPaidAmount',
            Type: 'FieldNumeric',
            Tooltip: 'החלק ששולם עד כה',
            Sortable: true,
            Order: 15,
        },
        PaymentDate: {
            Display: 'ת. תשלום',
            Id: 'PaymentDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו נתקבל תשלום מלא/חלקי בעבור הדוח',
            Sortable: true,
        },
        PaymentReferenceNumber: {
            Display: 'אסמכתה',
            Id: 'PaymentReferenceNumber',
            Type: 'FieldStandard',
            Tooltip: 'האסמכתה כפי שניתנה מהעיריה בגין תשלום חלקי/מלא',
            Sortable: true,
        },
        PaymentUrl: {
            Display: 'דף תשלום',
            Id: 'PaymentUrl',
            Type: 'FieldPayUrl',
            Tooltip: 'לינק לדף התשלום של הרשות בה נתקבל הדוח',
            Width: 100,
            align: 'center',
        },
        PlateNumber: {
            Order: 5,
            Display: 'לוחית רישוי',
            Id: 'Vehicle',
            Type: 'FieldEnum',
            onClick: () => { },
            Tooltip: 'לוחית הרישוי של הרכב בזמן העבירה',
            Sortable: true,
            Namespace: 'vehicles',
        },
        VehiclePlateNumber: {
            Order: 5,
            Display: 'לוחית רישוי',
            Id: 'PlateNumber',
            Type: 'FieldStandard',
            onClick: () => { },
            Tooltip: 'לוחית הרישוי של הרכב בזמן העבירה',
            Sortable: true,
        },
        Profile: {
            Display: 'לקוח',
            Id: 'TransferredTo',
            Type: 'FieldEnum',
            Tooltip: 'החברה אליה הרכב משוייך בזמן קבלת הדוח',
            Sortable: true,
            Namespace: 'clients',
            Order: 6,
        },
        ProfileName: {
            Display: 'לקוח',
            Id: 'ProfileName',
            Type: 'FieldStandard',
            Tooltip: 'החברה אליה הרכב משוייך בזמן קבלת הדוח',
            Sortable: true,
            Order: 2.3,
        },
        Reason: {
            Display: 'סיבת הדוח',
            Id: 'Reason',
            Type: 'FieldStandard',
            Tooltip: 'סיבת העבירה כפי שמופיעה בדו"ח',
            Sortable: true,
        },
        RelevantLaw: {
            Display: 'חוק מסמיך',
            Id: 'RelevantLaw',
            Type: 'FieldStandard',
            Tooltip: 'החוק הרלבנטי בגינו ניתן הדו"ח',
        },
        ReportCause: {
            Order: 19,
            Display: 'סעיף העבירה',
            map: (row) => row?.Name,
            Id: 'ReportCause',
            Type: 'FieldStandard',
            Tooltip: 'סעיף העבירה בתקנות בגינם ניתן הדו"ח',
            Sortable: true,
        },
        ReportNumber: {
            Order: 1,
            Display: 'מספר דוח',
            Id: 'ReportNumber',
            Type: 'FieldLink',
            Tooltip: 'מספר הדו"ח ע"פ הרשות',
            Sortable: true,
        },
        Status: {
            Order: 22,
            Display: 'סטטוס',
            Id: 'Status',
            Type: 'FieldStatus',
            Tooltip: 'סטטוס הדוח ע"פ העיריה',
            Sortable: true,
            Width: 130,
            align: 'center',
        },
        TransferCompleteDate: {
            Display: 'ת. הסבה לחברה',
            Id: 'TransferCompleteDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו נתקבל מהעיריה אישור להסבת הדוח',
            Sortable: true,
        },
        TransferError: {
            Display: 'שגיאת הסבה לחברה',
            Id: 'TransferError',
            Type: 'FieldStandard',
            Tooltip: 'הסיבה לכך שההסבה נכשלה',
            Sortable: true,
        },
        TransferredFromName: {
            Display: 'חברה',
            Id: 'TransferredFrom',
            Type: 'FieldEnum',
            Tooltip: 'הבעלים המקורי של הרכב כפי שמצויין במשרד הרישוי',
            Order: 2.2,
        },
        TransferredTo: {
            Display: 'לקוח',
            Id: 'TransferredTo',
            Type: 'FieldEnum',
            Tooltip: 'הבעלים החדשים של הרכב כפי שמצויין במשרד הרישוי',
            Order: 2.2,
        },
        TransferPdfUrl: {
            Display: 'קובץ הסבה לחברה',
            Id: 'TransferPdfUrl',
            mapItem: (row) => row,
            Type: 'FieldFile',
            Tooltip: 'קובץ PDF עם טופס ההסבה של החברה',
        },
        TransferPdfUrlAdmin: {
            Display: 'קובץ הסבה לחברה',
            Id: 'TransferPdfUrl',
            mapItem: (row) => row,
            Type: 'FieldFileByTransferId',
            Tooltip: 'קובץ PDF עם טופס ההסבה של החברה'
        },
        TransferSignedDate: {
            Display: 'ת. חתימת ע"וד להסבת חברה',
            Id: 'TransferSignedDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך והשעה בו עו"ד חתם על הסבת הדוח לחברה',
            Sortable: true,
        },
        TransferStatus: {
            Display: 'סטטוס הסבת חברה',
            Id: 'TransferStatus',
            Type: 'FieldStandard',
            Tooltip: 'סטטוס ההסבה של חברה ע"פ המערכת',
            Sortable: true,
            Order: 24,

        },
        TransferredDate: {
            Display: 'ת.  הפקת הסבת חברה',
            Id: 'TransferredDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך והשעה בו הוגשה ההסבה לעיריה',
            Sortable: true,
            Order: 23,
        },
        VehicleType: {
            Order: 2,
            Display: 'סוג הרכב',
            Id: 'VehicleType',
            Type: 'FieldVehicleType',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        VerificationDate: {
            Display: 'ת. אימות',
            Id: 'VerificationDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך והשעה האחרונים בהם המערכת וידאה מול העיריה את אמינות הנתונים',
            AdminOnly: true,
            Sortable: true,
            Order: 12,
        },

    },
    vehicles: {
        Enumerate: {
            Order: 2,
            Display: 'לוחית רישוי',
            Id: 'PlateNumber',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: true,
            map: (value, row) => row,
        },
        VehiclePlateNumber: {
            Order: 2,
            Display: 'לוחית רישוי',
            Id: 'PlateNumber',
            Type: 'FieldVehicle',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: true,
        },
        VehicleType: {
            Order: 1,
            Display: 'סוג הרכב',
            Id: 'VehicleType',
            SortField: 'Type',
            Type: 'FieldVehicleType',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Manufacturer: {
            Order: 3,
            Display: 'יצרן',
            Id: 'Manufacturer',
            Type: 'FieldStandard',
            Tooltip: 'יצרן הרכב',
            AdminOnly: false,
            Sortable: true,
        },
        Model: {
            Order: 5,
            Display: 'דגם',
            Id: 'Model',
            Type: 'FieldStandard',
            Tooltip: 'מודל הרכב',
            AdminOnly: false,
            Sortable: true,
        },
        Year: {
            Order: 6,
            Display: 'שנה',
            Id: 'Year',
            Type: 'FieldStandard',
            Tooltip: 'שנת העליה לכביש',
            AdminOnly: false,
            Sortable: true,
        },
        Color: {
            Order: 7,
            centeredHeader: true,
            Display: 'צבע',
            Id: 'Color',
            Type: 'FieldStandard',
            Tooltip: 'צבע הרכב',
            align: 'center',
            Width: 100,
            AdminOnly: false,
            Sortable: true,
        },
        IsManaged: {
            Order: 8,
            centeredHeader: true,
            Display: 'מנוהל',
            Id: 'IsManaged',
            Type: 'FieldBoolean',
            Tooltip: 'צבע הרכב',
            AdminOnly: true,
            Sortable: false,
            align: 'center',
            Width: 100,
        },
        Owner: {
            Order: 9,
            Display: 'בעלות',
            Id: 'Owner',
            Type: 'FieldEnum',
            Tooltip: 'הבעלים המקורי של הרכב כפי שמצויין במשרד הרישוי',
            Namespace: 'customers',
        },
        Profile: {
            Order: 4,
            Display: 'לקוח',
            Id: 'Profile',
            Type: 'FieldStandard',
            Tooltip: 'הלקוח אליו הרכב משוייך נכון לרגע זה',
            Sortable: true,
        },
        StartContractDate: {
            Order: 11,
            Display: 'ת. תחילת החוזה',
            Id: 'StartDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך שבו התחיל חוזה הלקוח על רכב זה',
        },
        EndContractDate: {
            Order: 12,
            Display: 'ת. סיום החוזה',
            Id: 'EndDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך שבו חוזה הלקוח על רכב זה פוקע',
        },
        ProfitCenter: {
            Order: 10,
            Display: 'מרכז רווח',
            Id: 'ProfitCenter',
            Type: 'FieldStandard',
            Tooltip: 'מרכז רווח',
            AdminOnly: false,
            Sortable: false,
        },
    },
    paidReports: {
        PayId: {
            Display: 'מזהה תשלום',
            Id: 'Address',
            Type: 'FieldStandard',
            Tooltip: 'המיקום בו נרשם הדוח ע"פ הפקח',
            Sortable: true,
        },
    },
    customers: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'ClientId',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Name: {
            Order: 2.5,
            Display: 'שם',
            Id: 'Name',
            Type: 'FieldStandard',
            Tooltip: 'שם הלקוח',
        },
        CreateDate: {
            Display: 'ת. יצירה',
            Id: 'ClientDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך יצירת הלינק',
        },
        ExpirationDate: {
            Display: 'ת. פקיעה',
            Id: 'ExpirationDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך פקיעת הלינק',
        },
        FirstAccessDate: {
            Display: 'ת. צפיה',
            Id: 'FirstAccessDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו הלינק נצפה לראשונה',
        },
        Client: {
            Display: 'שם לקוח',
            Id: 'Client',
            Type: 'FieldStandard',
            Tooltip: 'שם הלקוח',
            map: (value) => value.Name,
        },
        IdNumber: {
            Order: 2,
            Display: 'ח.פ',
            Id: 'IdNumber',
            Type: 'FieldStandard',
            Tooltip: 'חברה פרטית',
            Width: 100,
        },
        HasTransferForm: {
            Display: 'תבנית הסבה',
            Id: 'HasTransferForm',
            Type: 'FieldBoolean',
            Tooltip: '',
            align: 'center',
            centeredHeader: true,
        },
        HasOpeningPage: {
            Order: 3,
            Display: 'דף ראשון בהסכם',
            Id: 'HasOpeningPage',
            Type: 'FieldBoolean',
            Tooltip: '',
            align: 'center',
            centeredHeader: true,
        },
        HasGeneralContractForm: {
            Order: 4,
            Display: 'הצהרת חוכר כללית',
            Id: 'HasGeneralContractForm',
            Type: 'FieldBoolean',
            Tooltip: '',
            align: 'center',
            centeredHeader: true,
        },
        HasDriversLicense: {
            Order: 5,
            Display: 'תצלום רישיון נהיגה',
            Id: 'HasDriversLicense',
            Type: 'FieldBoolean',
            Tooltip: '',
            align: 'center',
            centeredHeader: true,
        },
        Fee: {
            Order: 6,
            Display: 'דמי ניהול',
            Id: 'Fee',
            Type: 'FieldStandard',
            Tooltip: 'דמי ניהול של החברה הגובה',
            align: 'center',
            centeredHeader: true,
        },
        InternalClientId: {
            Order: 7,
            Display: 'מזהה פנימי',
            Id: 'InternalClientId',
            Type: 'FieldStandard',
            Tooltip: 'מזהה פנימי של הלקוח אצל החברה',
            Width: 100,
        },
        Email: {
            Order: 8,
            Display: 'אימייל',
            Id: 'Email',
            Type: 'FieldStandard',
            Tooltip: 'כתובת מייל למשלוח לינק',
            Width: 200,
        },
        AllowsApproval: {
            Order: 9,
            Display: 'הרשאה לאישור תשלום',
            Id: 'AllowsApproval',
            Type: 'FieldBoolean',
            Tooltip: 'האם הלקוח רשאי לאשר דוחות לתשלום',
            align: 'center',
            centeredHeader: true,
            Width: 150,
        },
        AllowContractHtmlAggregation: {
            Order: 10,
            Display: 'תמיכה בחוזים ישנים',
            Id: 'AllowContractHtmlAggregation',
            Type: 'FieldBoolean',
            Tooltip: 'הגדרה פנימית של Betterway',
            align: 'center',
            centeredHeader: true,
        },
        ForbidTransfer: {
            Order: 11,
            Display: 'החרג הסבה ללקוח זה',
            Id: 'ForbidTransfer',
            Type: 'FieldBoolean',
            Tooltip: 'הסבות עבור לקוח זה לא יבוצעו',
            align: 'center',
            centeredHeader: true,
        },
        CustomerLink: {
            Display: 'לינק',
            Id: 'ShortToken',
            Type: 'FieldCustomerLink',
            Tooltip: 'מעבר לדף לקוח',
            centeredHeader: true,
        },
    },
    tollRoads: {
        AccountNumber: {
            Order: 1,
            Display: 'מספר חשבון',
            Id: 'AccountNumber',
            Type: 'FieldStandard',
            Tooltip: 'מספר החשבון בכביש האגרה',
            Sortable: true,
        },
        VehicleType: {
            Order: 2,
            Display: 'סוג הרכב',
            Id: 'VehicleType',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב',
            Sortable: true,
        },
        InvoiceNumber: {
            Order: 3,
            Display: 'מספר חשבונית',
            Id: 'InvoiceNumber',
            Type: 'FieldStandard',
            Tooltip: 'מספר החשבונית בחברה הגובה',
            Sortable: true,
        },
        PlateNumber: {
            Order: 4,
            Display: 'לוחית רישוי',
            Id: 'Vehicle',
            Type: 'FieldVehicle',
            map: (i) => i.Name,
            mapItem: (i) => i.Vehicle,
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: true,
        },
        LinkedClient: {
            Order: 5,
            Display: 'לקוח',
            Id: 'LinkedClient',
            Type: 'FieldEnum',
            Tooltip: 'הלקוח המקושר לנסיעה זו',
            Sortable: true,
            Namespace: 'customers',
        },
        DateTaken: {
            Order: 6,
            Display: 'ת. נסיעה',
            Id: 'DateTaken',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך הנסיעה בכביש האגרה',
            Sortable: true,
        },
        Amount: {
            Order: 7,
            Display: 'סכום לתשלום ללא מע"מ',
            Id: 'Amount',
            Type: 'FieldCurrency',
            Tooltip: 'הסכום שיש לשלם בעבור הנסיעה',
            Sortable: true,
        },
        EntryPoint: {
            Order: 8,
            Display: 'נקודת כניסה',
            Id: 'EntryPoint',
            Type: 'FieldStandard',
            Tooltip: 'המקטע הראשון אליו נכנס הרכב',
            Sortable: true,
        },
        ExitPoint: {
            Order: 9,
            Display: 'נקודת יציאה',
            Id: 'ExitPoint',
            Type: 'FieldStandard',
            Tooltip: 'המקטע האחרון ממנו יצא הרכב',
            Sortable: true,
        },
        SegmentCount: {
            Order: 10,
            Display: 'מספר מקטעים',
            Id: 'SegmentCount',
            Type: 'FieldStandard',
            Tooltip: 'מספר המקטעים שהרכב עבר באותה נסיעה',
            Sortable: true,
        },
        TollRoadStatus: {
            Order: 11,
            Display: 'סטטוס חשבונית',
            Id: 'TollRoadStatus',
            Type: 'FieldStandard',
            Tooltip: 'סטטוס החשבונית כפי שמופיע בחברת כבישי האגרה',
            map: (f) => f.Name,
            Sortable: true,
        },
        VerificationDate: {
            Order: 12,
            Display: 'ת. אימות',
            Id: 'VerificationDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך אימות הנסיעה במערכת BetterWay',
            Sortable: true,
        },
        LinkedDriver: {
            Order: 13,
            Display: 'נהג',
            Id: 'LinkedDriver',
            Type: 'FieldDriver',
            Tooltip: 'הנהג ברכב בזמן הנסיעה',
            Sortable: true,
        },
    },
    drivers: {
        IdNumber: {
            Order: 1,
            Display: 'ת.ז',
            Id: 'IdNumber',
            Type: 'FieldStandard',
            Tooltip: 'ת.ז של הנהג',
            Sortable: false,
        },
        Name: {
            Order: 2,
            Display: 'שם מלא',
            Id: 'Name',
            Type: 'FieldStandard',
            Tooltip: 'שם מלא של הנהג',
            Sortable: false,
        },
        PhoneNumber: {
            Order: 3,
            Display: 'מספר טלפון',
            Id: 'PhoneNumber',
            Type: 'FieldStandard',
            Tooltip: 'מספר הטלפון של הנהג',
            Sortable: false,
        },
        Email: {
            Order: 3.5,
            Display: 'מייל',
            Id: 'Email',
            Type: 'FieldStandard',
            Tooltip: 'מייל של הנהג',
            Sortable: false,
        },
        City: {
            Order: 4,
            Display: 'עיר',
            Id: 'City',
            Type: 'FieldStandard',
            Tooltip: 'עיר',
            Sortable: false,
        },
        Street: {
            Order: 5,
            Display: 'רחוב',
            Id: 'Street',
            Type: 'FieldStandard',
            Tooltip: 'רחוב',
            Sortable: false,
        },
        HouseNumber: {
            Order: 6,
            Display: 'מספר בית',
            Id: 'HouseNumber',
            Type: 'FieldStandard',
            Tooltip: 'מספר בית',
            Sortable: false,
        },
        ZipCode: {
            Order: 7,
            Display: 'מיקוד',
            Id: 'ZipCode',
            Type: 'FieldStandard',
            Tooltip: 'מיקוד',
            Sortable: false,
        },
        DriverNumber: {
            Order: 8,
            Display: 'מספר נהג במערכת CRM',
            Id: 'DriverNumber',
            Type: 'FieldStandard',
            Tooltip: 'מספר הנהג במערכת הפנימית של הארגון שלך',
            Sortable: false,
        },
        TrackerDriverId: {
            Order: 9,
            Display: 'קוד מערכת ניתור',
            Id: 'TrackerDriverId',
            Type: 'FieldStandard',
            Tooltip: 'קוד מערכת ניתור',
            Sortable: false,
        },
        LicenseNumber: {
            Order: 10,
            Display: 'מספר רשיון נהיגה',
            Id: 'LicenseNumber',
            Type: 'FieldStandard',
            Tooltip: 'מספר רשיון נהיגה',
            Sortable: false,
        },
        HasDriversLicense: {
            Order: 10,
            Display: 'קיים תצלום רשיון נהיגה',
            Id: 'HasDriversLicense',
            Type: 'FieldBoolean',
            Tooltip: 'קיים תצלום רשיון נהיגה',
            Sortable: false,
        },
        HasTransferForm: {
            Order: 11,
            Display: 'קיים תצהיר',
            Id: 'HasTransferForm',
            Type: 'FieldBoolean',
            Tooltip: 'קיים תצהיר',
            Sortable: false,
        },
        IsManager: {
            Order: 12,
            Display: 'נהג מנהל',
            Id: 'IsManager',
            Type: 'FieldBoolean',
            Tooltip: 'נהג מנהל',
            Sortable: false,
        },
        IsActive: {
            Order: 13,
            Display: 'פעיל',
            Id: 'IsActive',
            Type: 'FieldBoolean',
            Tooltip: 'נהג מנהל',
            Sortable: false,
        },
    },
    tasks: {
        AssignedUserName: {
            Order: 3,
            Display: 'משתמש משוייך',
            Id: 'AssignedUserName',
            Type: 'FieldStandard',
            Tooltip: 'המשתמש אליו משוייכת המשימה',
            Sortable: false,
            SortField: 'AssignedTo',
        },
        AssignedClientProfile: {
            Order: 3.1,
            Display: 'לקוח משוייך',
            Id: 'ClientProfile',
            Type: 'FieldEnum',
            onClick: () => { },
            Tooltip: 'הלקוח אליו משוייכת המשימה',
            Sortable: false,
            Namespace: 'clients',
            SortField: 'AssignedTo',
        },
        AssignedOwnerProfile: {
            Order: 3.2,
            Display: 'בעלים משוייך',
            Id: 'OwnerProfile',
            Type: 'FieldEnum',
            onClick: () => { },
            Tooltip: 'הבעלים אליו משוייכת המשימה',
            Sortable: false,
            SortField: 'AssignedTo',
        },
        AssignedDriverProfile: {
            Order: 3.3,
            Display: 'נהג משוייך',
            Id: 'DriverProfile',
            Type: 'FieldEnum',
            onClick: () => { },
            Tooltip: 'הנהג אליו משוייכת המשימה',
            Sortable: false,
            Namespace: 'drivers',
            SortField: 'AssignedTo',
        },
        DueDate: {
            Order: 4,
            Display: 'ת. יעד',
            Id: 'DueDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך היעד בו המשימה צריכה להסתיים',
            Sortable: true,
        },
        EmailReminderDate: {
            Order: 8,
            Display: 'ת. תזכורת',
            Id: 'EmailReminderDate',
            Type: 'FieldDateHour',
            Tooltip: 'התאריך בו נקבעה תזכורת למשימה',
        },
        Priority: {
            Order: 5,
            Display: 'דחיפות',
            Id: 'Priority',
            Type: 'FieldPriority',
            Tooltip: 'דחיפות המשימה',
            Sortable: true,
            align: 'center',
            centeredHeader: true,
        },
        IsOverdue: {
            Order: 9,
            Display: 'חריגה',
            Id: 'IsOverdue',
            Type: 'FieldBoolean',
            Tooltip: 'האם תאריך יעד המשימה עבר',
            align: 'center',
            centeredHeader: true,
        },
        IsComplete: {
            Display: 'הושלם',
            Id: 'IsComplete',
            Type: 'FieldIsComplete',
            Tooltip: 'סטטוס המשימה',
            Order: 0,
            align: 'center',
            centeredHeader: true,
        },
        EntityType: {
            Order: 0.5,
            Display: 'סוג פריט',
            Id: 'EntityType',
            Type: 'FieldEntityType',
            Tooltip: 'סוג הישות',
            align: 'center',
            centeredHeader: true,
        },
        CompletionDate: {
            Order: 8,
            Display: 'ת. ביצוע',
            Id: 'CompletionDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך ביצוע המשימה',
            Sortable: true,
        },
        CreateDate: {
            Order: 6,
            Display: 'ת. יצירה',
            Id: 'CreateDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך יצירת המשימה',
            Sortable: true,
        },
        CreatorName: {
            Order: 7,
            Display: 'שם היוצר',
            Id: 'CreatorName',
            Type: 'FieldStandard',
            Tooltip: 'שם יוצר המשימה',
        },
        Title: {
            Order: 1,
            Display: 'כותרת',
            Id: 'Title',
            Type: 'FieldStandard',
            Tooltip: 'כותרת המשימה',
            Sortable: true,
        },
        Body: {
            Order: 2,
            Display: 'תוכן',
            Id: 'Body',
            Type: 'FieldHtml',
            Tooltip: 'תוכן המשימה',
            Sortable: true,
        },
    },
    accidents: {
        CreateDate: {
            Order: 1,
            Display: 'ת. יצירה',
            Id: 'CreateDate',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך יצירת התאונה',
            centeredHeader: true,
            align: 'center',
        },
        VehiclePlateNumber: {
            Order: 2,
            Display: 'לוחית רישוי',
            Id: 'PlateNumber',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            centeredHeader: true,
            align: 'center',
        },
        AccidentType: {
            Order: 3,
            Display: 'סוג',
            Id: 'AccidentType',
            Type: 'FieldAccidentType',
            Tooltip: 'סוג הדיווח',
            centeredHeader: true,
            align: 'center',
        },
        AccidentDate: {
            Order: 4,
            Display: 'ת. האירוע',
            Id: 'Date',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך האירוע',
            centeredHeader: true,
            align: 'center',
        },
        Description: {
            Order: 5,
            Display: 'תיאור המקרה',
            Id: 'Description',
            Type: 'FieldStandard',
            Tooltip: 'תיאור המקרה',
        },
        FieldPdfStatus: {
            Order: 6,
            Display: 'סטטוס',
            Id: 'PdfStatus',
            Type: 'FieldAccidentPdfStatus',
            Tooltip: 'סטטוס הטופס',
            centeredHeader: true,
            align: 'center',
        },
        Document: {
            Order: 7,
            Display: 'טופס',
            Id: 'PdfUrl',
            Type: 'FieldAccidentPdf',
            Tooltip: 'טופס תאונה',
            centeredHeader: true,
            align: 'center',
        },
    },
    municipalities: {
        Id: {
            Order: 0,
            Display: 'מזהה',
            Id: 'Id',
            Type: 'FieldNumeric',
            Tooltip: 'מזהה שורה',
            AdminOnly: true,
            Sortable: true,
        },
        Municipality: {
            Display: 'עיריה',
            Id: 'Municipality',
            Type: 'FieldStandard',
            Tooltip: 'הרשות שמטעמה נתקבל הדוח',
            Sortable: true,
            AdminOnly: true,
            Order: 1,
        },

    },
};

export default {
    list: (namespace) => ({ ...fields[namespace] }),
};
