import FieldStandard from '@/components/Fields/FieldStandard.vue';
import FieldNumeric from '@/components/Fields/FieldNumeric.vue';
import FieldCurrency from '@/components/Fields/FieldCurrency.vue';
import FieldBoolean from '@/components/Fields/FieldBoolean.vue';
import FieldDateHour from '@/components/Fields/FieldDateHour.vue';
import FieldVehicle from '@/components/Fields/FieldVehicle.vue';
import FieldDriver from '@/components/Fields/FieldDriver.vue';
import FieldVehicleType from '@/components/Fields/FieldVehicleType.vue';
import FieldFile from '@/components/Fields/FieldFile.vue';
import FieldFileByTransferId from '@/components/Fields/FieldFileByTransferId.vue';
import FieldFileDriver from '@/components/Fields/FieldFileDriver.vue';
import FieldStatus from '@/components/Fields/FieldStatus.vue';
import FieldLink from '@/components/Fields/FieldLink.vue';
import FieldCustomerLink from '@/components/Fields/FieldCustomerLink.vue';
import FieldPayUrl from '@/components/Fields/FieldPayUrl.vue';
import FieldAlerts from '@/components/Fields/FieldAlerts.vue';
import FieldImage from '@/components/Fields/FieldImage.vue';
import FieldEnum from '@/components/Fields/FieldEnum.vue';
import FieldIsComplete from '@/components/Fields/FieldIsComplete.vue';
import FieldHtml from '@/components/Fields/FieldHtml.vue';
import FieldPriority from '@/components/Fields/FieldPriority.vue';
import FieldEntityType from '@/components/Fields/FieldEntityType.vue';
import FieldAccidentType from '@/components/Fields/FieldAccidentType.vue';
import FieldAccidentPdfStatus from '@/components/Fields/FieldAccidentPdfStatus.vue';
import FieldAccidentPdf from '@/components/Fields/FieldAccidentPdf.vue';

export default {
    FieldStandard,
    FieldNumeric,
    FieldDateHour,
    FieldVehicle,
    FieldDriver,
    FieldVehicleType,
    FieldFile,
    FieldFileByTransferId,
    FieldFileDriver,
    FieldStatus,
    FieldLink,
    FieldBoolean,
    FieldCurrency,
    FieldCustomerLink,
    FieldPayUrl,
    FieldImage,
    FieldAlerts,
    FieldEnum,
    FieldIsComplete,
    FieldHtml,
    FieldPriority,
    FieldEntityType,
    FieldAccidentType,
    FieldAccidentPdfStatus,
    FieldAccidentPdf,
};
