var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Field__Component" }, [
    _c(
      "div",
      [
        _vm._t("default", [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://app.betterway.co.il/i/" + _vm.value
              }
            },
            [
              _c(
                "b-button",
                { attrs: { type: "is-primary", size: "is-small" } },
                [_vm._v("פתח")]
              )
            ],
            1
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }